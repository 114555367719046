import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from "pinia";
import piniaPersist from 'pinia-plugin-persist'

//VeeValidate
import VeeValidatePlugin from "./plugins/validation"

//Font awesome
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

//Finestre modali
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';

//Toast notification
import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-sugar.css';

//Locales
import it from '@/locales/it.json';
import {setLocale} from '@vee-validate/i18n';
import { createI18n } from "vue-i18n";

import './assets/css/index.css'
import './assets/css/custom.css'

/* add icons to the library */
library.add(fas, far, fab);

const pinia = createPinia();
pinia.use(piniaPersist);

const vfm = createVfm();

export const i18n = createI18n({
  legacy: false,
  locale: "it",
  fallbackLocale: "it",
  messages: { it },
});

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(pinia);
app.use(VeeValidatePlugin);
app.use(ToastPlugin);
app.use(vfm);

app.component('font-awesome-icon', FontAwesomeIcon);
setLocale('it');

app.mount('#app');
