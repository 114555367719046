import { http } from "../http";

export const getDocuments = async (postData: any) => {
  const { data } = await http.post('/documents/getbycontracts/', postData,{});
  return data
}

export const getPdfDocument = async (postData: any) => {
  const { data } = await http.post('/documents/getpdf/', postData,{
    responseType: 'arraybuffer'
  });

  return data;
}


export const getComunications = async (postData: any) => {
  const { data } = await http.post('/customer/getcomunications/', postData,{});
  return data
}

export const checkStartDate = () =>{
  const start_date= new Date(process.env.VUE_APP_START_DATE);
  const today = new Date();
  return start_date <= today;
}


