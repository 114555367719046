<template>
  <VueFinalModal
    class="app-content"
    name="appPdfModal"
    :modal-id="this.modalId"
    content-class="w-full h-full flex flex-col bg-white dark:bg-gray-900 space-y-2"
    :hide-overlay="true"
    content-transition="vfm-fade"
    style="overflow-y: auto"
  >
    <div class="grid grid-cols-1 bg-gray-400">
      <div class="row p-2">
        <template v-if="isLoading"> {{ $t("pdf.view.loading") }}</template>

        <template v-else>
          <div class="grid grid-cols-5 gap-2">
            <div>
              <span v-if="showAllPages">
                {{ pageCount }} {{ $t("pdf.view.pages") }}
              </span>

              <span v-else>
                <button :disabled="page <= 1" @click="page--">❮</button>

                {{ page }} / {{ pageCount }}

                <button :disabled="page >= pageCount" @click="page++">❯</button>
              </span>
            </div>

            <div>
              <button-submit-component
                :text="$t('pdf.view.close')"
                class="flex justify-center rounded-md bg-regal-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-regal-cyan focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                iconImg="fa-solid fa-close"
                iconClass="text-white px-2"
                @click="closeModal()"
              />
            </div>
            <div>
              <button-submit-component
                :text="$t('pdf.view.print')"
                class="flex justify-center rounded-md bg-regal-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-regal-cyan focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                iconImg="fa-solid fa-print"
                iconClass="text-white px-2"
                @click="printPdf()"
              />
            </div>
            <div>
              <button-submit-component
                :text="$t('pdf.view.download')"
                class="flex justify-center rounded-md bg-regal-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-regal-cyan focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                iconImg="fa-solid fa-download"
                iconClass="text-white px-2"
                @click="downloadPDF()"
              />
            </div>
            <div>
              <input v-model="showAllPages" type="checkbox" />
              {{ $t("pdf.view.showallpages") }}
            </div>
          </div>
        </template>
      </div>

      <div class="row p-2 border-2">
        <vue-pdf-embed
          ref="pdfRef"
          :source="this.pdfSource"
          :page="this.page"
          @password-requested="handlePasswordRequest"
          @rendered="handleDocumentRender"
        />
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
import { VueFinalModal } from "vue-final-modal";
import ButtonSubmitComponent from "@/components/shared/element/buttonSubmitComponent.vue";

export default {
  name: "appPdfModal",
  props: {
    modalId: {
      type: String
    },
    streamPdf: {
      type: String
    }
  },
  components: {
    ButtonSubmitComponent,
    VueFinalModal,
    VuePdfEmbed,
  },
  beforeUpdate() {
    const streamPdf = this.streamPdf;
    this.pdfSource = "data:application/pdf;base64," + streamPdf;
  },
  data() {
    return {
      isLoading: true,
      page: null,
      pageCount: 1,
      pdfSource: "",
      showAllPages: true,
    };
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  methods: {
    async handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    handlePasswordRequest(callback, retry) {
      callback(prompt(retry ? "Enter password again" : "Enter password"));
    },
    closeModal() {
      this.$vfm.close(this.modalId);
    },
    printPdf() {
      this.$refs.pdfRef.print(300);
    },
    async downloadPDF() {
      const fileName = 'myDocument.pdf';
      this.base64ToPDF(await this.streamPdf, fileName);
    },
    base64ToPDF(base64String, fileName) {
      // Remove any non-base64 characters from the input

      // Decode the sanitized base64 string
      const byteCharacters = atob(base64String);

      // Convert to an ArrayBuffer
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob object from the ArrayBuffer
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a temporary URL for the Blob object
      const urlObject = window.URL.createObjectURL(blob);

      // Create a link element and set its attributes for downloading
      const linkElement = document.createElement('a');
      linkElement.href = urlObject;
      linkElement.download = fileName;

      // Trigger click event to start downloading
      linkElement.click();

      // Clean up by revoking the temporary URL object after some time
      setTimeout(() => {
        window.URL.revokeObjectURL(urlObject);
      }, 1000);
    },
  },
};
</script>

<style>
vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-content {
  padding: 24px 16px;
}

.right {
  float: right;
}
</style>
