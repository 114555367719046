import axios from "axios";

const headers = {
  Accept: "*/*"
}

class Http {
  instance : any | null;

  constructor() {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: process.env.VUE_APP_END_POINT_URL,
      headers
    });

    http.interceptors.request.use(
      (config) => {
        config.headers.authorization=process.env.VUE_APP_APY_KEY;
        return config;
      },
      (error) => {
        const { response } = error;
        return Promise.reject(response);
      }
    );

    this.instance = http;
    return http;
  }



  request(config: any): any {
    return http.request(config)
  }

  get(url: string, config: any): any {
    return http.get(url, config)
  }

  post(url: string, data: any, config: any): any {
    return http.post(url, data, config)
  }

  put(url: string, data: any, config: any): any {
    return http.put(url, data, config)
  }

  delete(url: string, config: any): any {
    return http.delete(url, config)
  }
}

export const http = new Http();
