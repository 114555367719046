import { configure, defineRule, ErrorMessage, Field, Form } from "vee-validate";
import {
    alpha_spaces as alphaSpaces,
    confirmed,
    email,
    max,
    max_value as maxVal,
    min,
    min_value as minVal,
    not_one_of as excluded, numeric,
    required,
} from '@vee-validate/rules';
import { localize } from "@vee-validate/i18n";
import it from '@/locales/vee-validator/it.json'

export default {
    install: function (app: any) {
        app.component("VeeForm", Form);
        app.component("VeeField", Field);
        app.component("ErrorMessage", ErrorMessage);

        defineRule("required", required);
        defineRule("tos", required);
        defineRule("min", min);
        defineRule("max", max);
        defineRule("alpha_spaces", alphaSpaces);
        defineRule("email", email);
        defineRule("min_value", minVal);
        defineRule("max_value", maxVal);
        defineRule("passwords_mismatch", (value: string, [target]: any, ctx: any) =>{
          if (value === ctx.form[target]){
            return true;
          }
          return app.config.globalProperties.$t('custom_validators_rules.password_mistmatch');
        });
        defineRule("excluded", excluded);
        defineRule("country_excluded", excluded);
        defineRule("numeric", numeric);
        defineRule("confirmed", confirmed);
        defineRule('uppercaseSymbol', (value: string) => {
          const uppercaseRegex = /.*[A-Z].*/;
          const symbolRegex = /.*[\W_].*/;

          if (!value.match(uppercaseRegex)) {
            return app.config.globalProperties.$t('custom_validators_rules.uppercase_error');
          }

          if (!value.match(symbolRegex)) {
            return app.config.globalProperties.$t('custom_validators_rules.symbol_error');
          }

          // Valore valido
          return true;
        });

      configure({
        generateMessage: localize({
          it
        }),
        validateOnBlur: true,
        validateOnChange: true,
        validateOnInput: false,
        validateOnModelUpdate: true,
      });
    },
}
