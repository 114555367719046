<template>
  <Disclosure as="nav" class="bg-regal-blue w-[100%]" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">{{$t('nav_bar.profile.title')}}</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex bg-white p-1">
            <router-link
              v-if="!store.customer.isAdmin"
              class="flex justify-between"
              :to="userLoggedIn ? '/home' : '/'"
            >
              <font-awesome-icon
                icon="fas fa-home"
                class="bg-white text-regal-blue gap-2 h-8 w-auto"
              />
              <img class="block h-8 w-auto lg:hidden" src="@/assets/images/logo_20240702.svg" alt="Amundi" />
              <img class="hidden h-8 w-auto lg:block" src="@/assets/images/logo_20240702.svg" alt="Amundi" />
            </router-link>
            <router-link
              v-if="store.customer.isAdmin"
              class="flex justify-between"
              :to="userLoggedIn ? '/admin' : '/'"
            >
              <font-awesome-icon
                icon="fas fa-home"
                class="bg-white text-regal-blue gap-2 h-8 w-auto"
              />
              <img class="block h-8 w-auto lg:hidden" src="@/assets/images/logo_20240702.svg" alt="Amundi" />
              <img class="hidden h-8 w-auto lg:block" src="@/assets/images/logo_20240702.svg" alt="Amundi" />
            </router-link>

          </div>
          <div class="hidden sm:ml-6 sm:block" v-if="!store.customer.isAdmin">
            <div id="navBtn" class="flex space-x-4 " v-if="userLoggedIn">
              <router-link
                v-for="item in navigation"
                :key="item.name" as="a"
                :to="item.to"
                class='p-3 text-white hover:bg-gray-700 hover:text-white'
                :aria-current="item.current ? 'page' : undefined">
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-if="userLoggedIn"
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <p class="text-white">{{store.customer.nome}} {{store.customer.cognome}}</p>
          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span class="sr-only">{{$t('nav_bar.profile.title')}}</span>
                <font-awesome-icon
                  icon="fas fa-user"
                  class="text-white h-8 w-8"
                />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-slot="{ active }">
                  <router-link
                    to="profile"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                    {{$t('nav_bar.profile.profile')}}
                  </router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="#" @click.prevent="logout()" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{$t('nav_bar.profile.logout')}}</a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden" v-if="userLoggedIn">
      <div id="navBtn" class="space-y-1 px-2 pb-3 pt-2" v-if="!store.customer.isAdmin">
        <router-link
          v-for="item in navigation"
          :key="item.name" as="a"
          :to="item.to"
          class='p-3 text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium'
          >
          {{ item.name }}
        </router-link>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useCustomerStore } from "@/stores/customer";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const router = useRouter();
const store = useCustomerStore();
const { t } = useI18n();
const { userLoggedIn } = storeToRefs(store);

const navigation = [
  { name: t('nav_bar.dashboard'),
    to: 'home',
    current: false
  },
  { name: t('nav_bar.search'),
    to: 'search',
    current: false
  },
]

const logout = () => {
  store.setIslogged(false);
  store.setCustomer({});

  router.push({
    name: 'login'
  })
}
</script>

<style scoped>
#navBtn a.router-link-exact-active {
  background-color: dimgray;
  cursor: pointer;
}
</style>
