import {defineStore} from 'pinia';

export const useCustomerStore = defineStore("customer", {
  state: () => ({
    userLoggedIn: false,
    customer: {},
  }),
  actions: {
    async setCustomer(customer: object) {
      this.customer = customer;
    },

    async getCustomer() {
      return this.customer;
    },

    async setIslogged(value:boolean){
      this.userLoggedIn  =value;
    },

    async getIslogged(){
      this.userLoggedIn;
    },

    async removeCustomer() {
      this.customer = {};
    },
  },
  getters: {
    getCurrentCustomer: (state) => {
      return state.customer;
    },
    getIsAdmin: (state) => {
      const user: any = {...state.customer};
      if (user?.isAdmin === 1){
        return true;
      }
      return false;
    },
  },
  persist: {
    enabled: true
  }
});
