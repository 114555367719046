import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useCustomerStore } from "@/stores/customer";

const authAdminGuard = (to: any, from: any, next: any) => {
  const store = useCustomerStore();
  const isAdmin = store.getIsAdmin;
  if (isAdmin) {
    next(); // Lasciare passare l'accesso se l'utente ha il ruolo "admin"
  } else {
    next({ name: "login" }); // Reindirizza a una pagina di login o ad altre pagine a seconda delle tue esigenze.
  }
};

const authCustomerGuard = (to: any, from: any, next: any) => {
  const store = useCustomerStore();
  const isAdmin = store.getIsAdmin;
  if (!isAdmin) {
    next(); // Lasciare passare l'accesso se l'utente ha il ruolo "admin"
  } else {
    next({ name: "login" }); // Reindirizza a una pagina di login o ad altre pagine a seconda delle tue esigenze.
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    beforeEnter: authCustomerGuard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/RegisterView.vue'),
    beforeEnter: authCustomerGuard
  },
  {
    path: '/registration/:id/:registration_token',
    name: 'registration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/ActivateCustomerView.vue'),
    beforeEnter: authCustomerGuard
  },
  {
    path: '/reset_password/:id/:resetpassword_token',
    name: 'reset_password',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/ResetPassword2View.vue'),
  },
  {
    path: '/totp',
    name: 'totp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/TotpView.vue')
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/ResetPasswordView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/profile/ProfileView.vue'),
    children:[
      { path: ":pathMatch(.*)*", redirect: "/" },
    ],
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/footer/ContactView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/footer/PrivacyView.vue')
  },
  {
    path: '/search',
    name: 'search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/search/SearchView.vue'),
    beforeEnter: authCustomerGuard,
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/admin',
    name: 'adminhome',
    beforeEnter: authAdminGuard,
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminView.vue'),
    children: [
      {
        path: 'changepassword',
        name: 'adminchangepassword',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminResetPasswordView.vue'),
      },
      {
        path: 'showcustomermessages',
        name: 'showcustomermessages',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/CustomerMessagesView.vue'),
      },
      {
        path: 'showcustomerdocuments',
        name: 'showcustomerdocuments',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/CustomerDocumentsView.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const store = useCustomerStore();

  if (!to.meta.requiresAuth) {
    next();
    return;
  }

  if (store.userLoggedIn) {
    next();
  } else {
    next({ name: "login" });
  }
});

export default router
